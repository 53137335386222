import {getInsightsUrl, reportTraceData} from '@github-ui/internal-api-insights'
import {reactFetchJSON} from '@github-ui/verified-fetch'
import {useEffect, useState} from 'react'

import {baseEmptyStateLoading, baseEmptyStateNotLoading} from '../contexts/DeferredCommitDataContext'
import type {DeferredCommitData, DeferredData} from '../types/commits-types'

export function useLoadDeferredCommitData(url: string, updatedAt?: number): DeferredData {
  const [state, setState] = useState<DeferredData>(baseEmptyStateLoading)

  useEffect(() => {
    if (!url) return

    let cancelled = false
    const update = async () => {
      setState(baseEmptyStateLoading)
      const response = await reactFetchJSON(getInsightsUrl(url))

      if (cancelled) {
        return
      }

      try {
        if (response.ok) {
          const data = await response.json()
          data.loading = false
          if (data) {
            reportTraceData(data)
            setState(data)
          }
        } else {
          setState(baseEmptyStateNotLoading)
        }
      } catch (e) {
        setState(baseEmptyStateNotLoading)
      }
    }

    update()

    return function cancel() {
      cancelled = true
    }
  }, [url, updatedAt])

  return state
}

export function useLoadSingleDeferredCommitData(url: string): DeferredCommitData | null {
  const [state, setState] = useState<DeferredCommitData | null>(null)

  useEffect(() => {
    if (!url) return

    const update = async () => {
      const response = await reactFetchJSON(getInsightsUrl(url))

      try {
        if (response.ok) {
          const data = await response.json()
          if (data) {
            reportTraceData(data)
            setState(data.data)
          }
        }
      } catch (e) {
        //noop
      }
    }

    update()
  }, [url])

  return state
}
